import React, { useState } from 'react';
import { graphql } from 'gatsby';
import ReactPlayer from 'react-player';
import Image from '../components/Image';
import {
  Grid,
  Typography,
  Link,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
} from '@mui/material';

import Description from '@mui/icons-material/Description';
import FileDownload from '@mui/icons-material/FileDownload';

import Hero, { BackButton, Text, Tagline, Title } from '../sections/hero/hero';
import Layout from '../components/layout/layout';
import Markdown from '../components/Markdown';
import Section from '../components/section';
import Episodes from '../sections/episodes';
import CallToAction from '../sections/CallToAction';
import PartnerCategories from '../components/PartnerCategories';
import LeadGenDialog from '../components/LeadGenDialog';

const PartnerPage = ({
  data: {
    background: {
      childImageSharp: { gatsbyImageData: backgroundImage },
    },
  },
  pageContext: {
    targetAudience: { targetAudience },
    targetAudienceChallenges: { targetAudienceChallenges },
    partnerOffering: { partnerOffering },
    summary: { summary },
    name,
    videoUrls,
    website,
    categories,
    logoLight,
    logoDark,
    linkedin,
    relatedPodcasts,
    whitepapers,
  },
  location: { pathname },
}) => {
  const [dialog, setDialog] = useState({ open: false });

  const handleClickOpen = ({ slug, title, downloadUrl }) => {
    setDialog({
      open: true,
      callToAction: 'Whitepaper herunterladen',
      slug,
      title,
      downloadUrl,
    });
  };

  const handleClose = () => {
    setDialog({ open: false });
  };
  return (
    <Layout title={name} description={summary} pathname={pathname}>
      <Hero backgroundImage={backgroundImage} backgroundColor="#EFEFEF">
        <BackButton />
        <Tagline>Partner-Netzwerk</Tagline>
        <Title>Wir bringen euch zusammen</Title>
        <Text>
          Schau dir {name} in Ruhe an. Wenn du möchtest, dass wir euch
          zusammenbringen, dann vereinbare einen Termin am Ende der Seite.
        </Text>
      </Hero>
      <Section>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
            <Image srcDark={logoDark} srcLight={logoLight} alt={name} mb={2} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <PartnerCategories categories={categories} />
            <Typography variant="h1" gutterBottom>
              {name}
            </Typography>
            <Typography variant="summary" gutterBottom>
              {summary}
            </Typography>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              sx={{ marginTop: 2 }}
            >
              <Typography variant="body1" gutterBottom>
                <Link href={website} target="_blank">
                  Website
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link href={linkedin} target="_blank">
                  LinkedIn
                </Link>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Section>
      <Section title="Zielgruppe">
        <Markdown>{targetAudience}</Markdown>
      </Section>
      <Section title="Herausforderungen der Zielgruppe">
        <Markdown>{targetAudienceChallenges}</Markdown>
      </Section>
      <Section title="Unsere Lösung">
        <Markdown>{partnerOffering}</Markdown>
      </Section>
      {whitepapers && (
        <Section title="Whitepaper">
          <List>
            {whitepapers.map(({ id: slug, title, description, publicUrl }) => (
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() =>
                      handleClickOpen({ slug, title, downloadUrl: publicUrl })
                    }
                  >
                    <FileDownload />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <Description />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={title} secondary={description} />
              </ListItem>
            ))}
          </List>
        </Section>
      )}
      {videoUrls && (
        <Section title="Video">
          {videoUrls.map((url) => (
            <ReactPlayer url={url} controls />
          ))}
        </Section>
      )}
      {relatedPodcasts && (
        <Episodes
          filter={relatedPodcasts}
          title={`Alle Podcast mit ${name}`}
          hideTags
        />
      )}
      <CallToAction
        title={`Wir stellen euch ${name} vor`}
        text="Vereinbare mit Jonas einen Termin und besprich mit ihm eure Herausforderungen. Gemeinsam schaut ihr, welcher Partner für euch passend sein kann."
        label="Termin vereinbaren"
        href="https://calendar.app.google/1EZAsChBVHFGvZqG7"
      />
      <LeadGenDialog {...dialog} onClose={handleClose} />
    </Layout>
  );
};

export const query = graphql`
  query MyQuery {
    background: file(relativePath: { eq: "backgrounds/bg-consulting.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;

export default PartnerPage;
