import React from 'react';
import LeadgenForm from '../leadgenForm';
import { Dialog, DialogContent } from '@mui/material';

const LeadGenDialog = ({
  open,
  onClose,
  title,
  slug,
  downloadUrl,
  callToAction,
}) => (
  <Dialog open={open} onClose={onClose}>
    <DialogContent>
      <LeadgenForm
        title={title}
        slug={slug}
        downloadUrl={downloadUrl}
        callToAction={callToAction}
      />
    </DialogContent>
  </Dialog>
);
export default LeadGenDialog;
